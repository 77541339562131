import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import About from "../components/About";
import ConfigContext from "../context/ConfigContext";

function AboutPage() {
  const config = useContext(ConfigContext);

  return (
    <Layout>
      <div className="about-container">
        <Helmet title={`About | ${config.siteTitle}`} />
        <About />
      </div>
    </Layout>
  );
}

export default AboutPage;
